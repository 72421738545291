<template>
  <v-row v-if="$can('can_see_map')" class="fill-height full-width" dense>

    <v-navigation-drawer enable-resize-watcher v-model="drawer" hide-overlay temporary style="position:absolute;">
      <v-list flat class="list transparent">
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Map Layers
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            PSAP 911
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.PSAP911.display"
              @click="commonLayerSwitcher('PSAP911')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Police Departments
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.policeDepartments.display"
              @click="commonLayerSwitcher('policeDepartments')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Fire Stations
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.fireStations.display"
              @click="commonLayerSwitcher('fireStations')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Hospitals
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.hospitals.display"
              @click="commonLayerSwitcher('hospitals')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Parks
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.parks.display" @click="commonLayerSwitcher('parks')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Recent Earthquakes
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.earthquakes.display"
              @click="commonLayerSwitcher('earthquakes')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Airports
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.airports.display"
              @click="commonLayerSwitcher('airports')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Current Wildfires
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.wildfires.display"
              @click="commonLayerSwitcher('wildfires')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            North American Rail Network Lines
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.railLines.display"
              @click="commonLayerSwitcher('railLines')"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <v-divider />

        <template v-if="loadingCustomMapLayers">
          <v-list-item :style="{
            'justify-content': 'center'
          }">
            <v-progress-circular indeterminate color="primary" />
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item v-for="(layer, index) in customMapLayers" :key="layer.id">
            <v-list-item-content class="font-weight-small">
              {{ layer.name }}
            </v-list-item-content>
            <template v-if="customMapLayersLoadingsStates[layer.id]">
              <v-list-item-action>
                <v-progress-circular :size="22" indeterminate color="primary" />
              </v-list-item-action>
            </template>
            <template v-else>
              <v-list-item-action>
                <v-switch dense flat inset v-model="layer.display" @change="customLayerSwitcher(index, layer.id)" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>

        <v-divider />

      </v-list>
    </v-navigation-drawer>
    <div id="open_filter_panel">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="rounded-0" color="white" fab v-bind="attrs" x-small @click.stop="drawer = !drawer" v-on="on">
            <v-icon color="grey">mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("map_filters") }}</span>
      </v-tooltip>
    </div>
    <v-col id="map-container" cols="12 py-0 d-flex"></v-col>
    <div id="open_map_button">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="rounded-0" color="white" fab v-bind="attrs" x-small @click="openMap" v-on="on">
            <v-icon color="grey">mdi-window-maximize</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open_map") }}</span>
      </v-tooltip>
    </div>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { loadModules } from 'esri-loader'
import apiService from '@/modules/api/csp'
import { EventBus } from '@/app/events/eventBus'
/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'

export default {
  name: 'MapsTab',
  data: () => ({
    graphic: null,
    esriRequest: null,
    locator: null,
    SpatialReference: null,
    Point: null,
    CoordinateFormatter: null,
    TextSymbol: null,
    lng: -105,
    lat: 43.95,
    zoom: 3.2,
    themeMode: 'dark-gray-vector',
    drawer: null,
    FeatureLayer: null,
    Layer: null,
    filter: {
      parks: {
        url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Parks/FeatureServer/0',
        display: false
      },
      earthquakes: {
        url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USGS_Seismic_Data_v1/FeatureServer/0',
        display: false
      },
      airports: {
        url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Airports_by_scale/FeatureServer/1',
        display: false
      },
      wildfires: {
        url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USA_Wildfires_v1/FeatureServer/0',
        display: false
      },
      railLines: {
        url: 'https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_Passenger_Rail/FeatureServer/0',
        display: false
      },
      PSAP911: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/PSAP_911_Service_Area_Boundaries/FeatureServer/0',
        display: false
      },
      policeDepartments: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Local_Law_Enforcement_Locations/FeatureServer/0',
        display: false
      },
      fireStations: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Fire_Station/FeatureServer/0',
        display: false
      },
      hospitals: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Hospital/FeatureServer/0',
        display: false
      }
    },
    esriConfig: null,
    loadingCustomMapLayers: false,
    customMapLayersLoadingsStates: {},
    customMapLayers: []
  }),
  mixins: [mapLocationMixin],
  computed: {
    ...mapGetters('held', ['heldGetter']),
    ...mapGetters('global', ['callGetter'])
  },
  watch: {
    'heldGetter.lat' () {
      this.setLocations(this.graphic, this.heldGetter)
    },
    async 'callGetter.clearingMap' (val) {
      if (val) {
        this.clearLocation()
        await this.clearingMapAction(false)
      }
    }
  },
  methods: {
    ...mapActions('global', ['clearingMapAction']),
    async loadMap () {
      loadModules(
        [
          'esri/Map',
          'esri/views/MapView',
          'esri/Graphic',
          'esri/config',
          'esri/widgets/Search',
          'esri/widgets/Fullscreen',
          'esri/widgets/BasemapToggle',
          'esri/widgets/Popup',
          'esri/request',
          'esri/rest/locator',
          'esri/geometry/SpatialReference',
          'esri/geometry/Point',
          'esri/geometry/coordinateFormatter',
          'esri/symbols/TextSymbol',
          'esri/widgets/BasemapGallery',
          'esri/layers/FeatureLayer',
          'esri/widgets/Expand',
          'esri/widgets/BasemapGallery/support/LocalBasemapsSource',
          'esri/Basemap'
        ],
        {
          css: true
        }
      ).then(
        ([
          ArcGISMap,
          MapView,
          Graphic,
          esriConfig,
          Search,
          Fullscreen,
          BasemapToggle,
          Popup,
          esriRequest,
          locator,
          SpatialReference,
          Point,
          CoordinateFormatter,
          TextSymbol,
          BasemapGallery,
          FeatureLayer,
          Expand,
          LocalBasemapsSource,
          Basemap,
          Layer
        ]) => {
          this.graphic = Graphic
          this.esriRequest = esriRequest
          this.locator = locator
          this.SpatialReference = SpatialReference
          this.Point = Point
          this.CoordinateFormatter = CoordinateFormatter
          this.TextSymbol = TextSymbol
          this.FeatureLayer = FeatureLayer
          this.Layer = Layer // Store Layer class

          esriConfig.apiKey = process.env.VUE_APP_ESRI_MAP_API_KEY

          this.esriConfig = esriConfig

          const map = new ArcGISMap({
            basemap: (localStorage.getItem('map-style')) ? localStorage.getItem('map-style') : this.themeMode
          })

          this.view = new MapView({
            map: map,
            center: [this.lng, this.lat],
            zoom: this.zoom,
            container: document.getElementById('map-container')
          })
          this.view.ui.add(
            document.getElementById('open_map_button'),
            'top-left'
          )
          this.addSearch(Search)
          this.mapToggle(BasemapToggle)
          this.fullScreen(Fullscreen)
          this.setBaseMapsSwitcher(BasemapGallery, Expand, LocalBasemapsSource, Basemap)
          this.view.ui.add(
            document.getElementById('open_filter_panel'),
            'top-right'
          )
          this.setActiveLayers(['PSAP911', 'policeDepartments', 'fireStations', 'hospitals', 'parks', 'earthquakes', 'airports', 'wildfires', 'railLines'])
          this.setActiveCustomLayers()
          this.view.map.watch('basemap', () => {
            localStorage.setItem('map-style', this.view.map.basemap.id)
          })
          this.view.when(() => {
            this.view.on('click', (event) => {
              if (event.button === 2) {
                this.reverseAddressOnClick(this.Point, this.SpatialReference, event.mapPoint.latitude, event.mapPoint.longitude, event)
              }
            })
          })
        }
      )
    },
    openMap () {
      window.open(`/map/${this.map.id}`, '_blank')
    },

    commonLayerSwitcher (layerName) {
      if (this.filter[layerName].display) {
        this.displayLayers(this.FeatureLayer, this.filter[layerName].url, layerName)
        localStorage.setItem(layerName, this.filter[layerName].display)
      } else {
        this.removeLayers(layerName)
        localStorage.setItem(layerName, this.filter[layerName].display)
      }
    },
    async customLayerSwitcher (layerIndex, layerId) {
      if (this.customMapLayers[layerIndex].display) {
        this.customMapLayersLoadingsStates[layerId] = true

        const { error, refreshTokenIntervalId } = await this.displayCustomMapLayers(this.customMapLayers[layerIndex], this.view)
        if (error) {
          this.customMapLayers[layerIndex].display = false
          this.removeLayers(this.customMapLayers[layerIndex].name)
          localStorage.setItem(this.customMapLayers[layerIndex].name, false)
          this.notificationSnackBarAction({
            text: 'Please check if the layer was created correct',
            color: 'warning',
            show: true
          })
        } else {
          console.log('we have successful run!')
        }
        this.customMapLayers[layerIndex].refreshTokenIntervalId = refreshTokenIntervalId
        this.customMapLayersLoadingsStates[layerId] = false
        localStorage.setItem(this.customMapLayers[layerIndex].name, true)
      } else {
        this.removeLayers(this.customMapLayers[layerIndex].name)
        localStorage.setItem(this.customMapLayers[layerIndex].name, false)
        clearInterval(this.customMapLayers[layerIndex].refreshTokenIntervalId)
      }
    },
    setActiveLayers (layerList) {
      layerList.forEach(item => {
        const filterStatus = localStorage.getItem(item)
        if (filterStatus === 'true') {
          this.filter[item].display = true
          this.displayLayers(this.FeatureLayer, this.filter[item].url, item)
        }
      })
    },
    async setActiveCustomLayers () {
      this.customMapLayers.forEach((customLayer, index) => {
        const filterStatus = localStorage.getItem(customLayer.name)
        if (filterStatus === 'true') {
          this.customMapLayers[index].display = true
          this.customLayerSwitcher(index)
        }
      })
    },
    async loadCustomMapLayers () {
      if (this.$can('can_add_contact')) {
        this.loadingCustomMapLayers = true
        try {
          const res = await apiService.mapLayers.getCustomMapLayers()
          this.customMapLayersLoadingsStates = res.data.reduce((acc, currentLayer) => ({ ...acc, [currentLayer.id]: false }), {})
          this.customMapLayers = res.data
        } catch (error) {
          console.log('error', error)
          this.notificationSnackBarAction({
            text: 'Please check if the layers were loaded correct',
            color: 'warning',
            show: true
          })
        } finally {
          this.loadingCustomMapLayers = false
        }
      }
    },
    async reloadMapAndCleanLayers () {
      this.customMapLayers.forEach(certainCustomLayer => {
        this.removeLayers(certainCustomLayer.name)
        localStorage.removeItem(certainCustomLayer.name)
      })
      await this.loadCustomMapLayers()
    }
  },
  created () {
    EventBus.$on('sip-address-changed', this.reloadMapAndCleanLayers)
  },
  async mounted () {
    this.loadMap()
    await this.createMap()

    await this.loadCustomMapLayers()

    this.$event.listen('clear-map-locations', () => {
      this.clearLocation()
    })
  },
  beforeDestroy () {
    EventBus.$off('sip-address-changed', this.reloadMapAndCleanLayersInLocalStorage)
    this.customMapLayers.forEach(certainLayer => {
      if (certainLayer.refreshTokenIntervalId) {
        clearInterval(certainLayer.refreshTokenIntervalId)
      }
    })
  }
}
</script>
<style lang="scss">
.esri-popup__main-container {
  background-color: #1e1e1e !important;
}

.esri-feature__size-container {
  background-color: #f0f0f0 !important;
}

.esri-map-row {
  border-bottom: 1px solid #cdcdcd;
}
</style>
